import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import PageFormLayout from '../../components/PageFormLayout/PageFormLayout';
import { reset } from '../../services/api.service';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const resetState = useSelector(state => state.formReducer.reset);
  let { token } = useParams();

  const handleSubmit = () => {
    reset({ new_password: password, conf_new_password: confPassword, token });
  };

  ValidatorForm.addValidationRule('isPasswordMatch', value => value === password);

  return (
    <PageFormLayout>
      <h2 className="card-title">Reset Password</h2>
      {resetState.success ? (
        <p className="register-success">Done. Your Password has been reset.</p>
      ) : (
        <div className="form">
          <ValidatorForm onSubmit={handleSubmit}>
            <TextValidator
              label="New Password"
              variant="outlined"
              type="password"
              validators={['required', 'minStringLength:12']}
              errorMessages={['Password is required', 'Password should be at least 12 characters']}
              onChange={e => setPassword(e.target.value.trim())}
              value={password}
            />
            <TextValidator
              label="Confirm Password"
              variant="outlined"
              type="password"
              validators={['required', 'isPasswordMatch']}
              errorMessages={['Field is required', "Passwords don't match"]}
              onChange={e => setConfPassword(e.target.value.trim())}
              value={confPassword}
            />

            <div className="action-buttons">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                disabled={resetState.loading}
              >
                {resetState.loading ? <CircularProgress size={28} /> : 'Reset'}
              </Button>
              {resetState.error && (
                <p className="MuiFormHelperText-root Mui-error">{resetState.error}</p>
              )}
            </div>
          </ValidatorForm>
        </div>
      )}

      <div className="action-links">
        <Link className="underlined" to="/login">
          login
        </Link>
        <Link to="signup">Register</Link>
      </div>
    </PageFormLayout>
  );
}

export default ResetPassword;
