export const saveToken = token => {
  saveItemToLS('token', token);
};

export const getToken = () => getItemFromLS('token');

export const removeToken = () => {
  removeItemFromLS('token');
};

export const isLoggedIn = () => !!getToken();

export const saveItemToLS = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItemFromLS = key => localStorage.getItem(key);

export const removeItemFromLS = key => {
  localStorage.removeItem(key);
};
