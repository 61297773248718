import { useRef } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import Logo from '../../components/Logo/Logo';
import RegisterForm from '../../components/RegisterForm/RegisterForm';

import { ReactComponent as DataSources } from '../../assets/images/data-sources.svg';
import { ReactComponent as Tools } from '../../assets/images/tools.svg';
import { isLoggedIn } from '../../services/localStorage.service';

import './Landing.scss';

function Landing() {
  const RegisterFormRef = useRef(null);
  const loggedIn = isLoggedIn();

  const scrollToRegister = () => {
    RegisterFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div id="landing">
      <header>
        <div className="width-wrapper-header">
          <div className="header-logo">
            <Logo />
          </div>
          <h1>Welcome to ECETOC heatDB</h1>
          <p>
            heatDB or Human Exposure Assessment Tools Database is a public directory of exposure
            data sources as well as available tools for exposure.
          </p>
          <div className="header-buttons">
            <Button variant="outlined" color="primary" disableElevation onClick={scrollToRegister}>
              register
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/login"
              disableElevation
            >
              {loggedIn ? 'Database' : 'login'}
            </Button>
          </div>
        </div>
      </header>

      <section className="section1">
        <div className="width-wrapper">
          <h2>About ECETOC heatDB</h2>
          <p className="bold-p">
            The Human Exposure Assessment Tools Database (heatDB) is a resource for risk assessors
            to use to quickly search and locate human exposure tools and data available in the
            public domain. Available sources of exposure data have been gathered, structured and
            categorised into a harmonised system.
          </p>
          <p>
            Additionally, available tools for exposure assessment were gathered and categorised into
            the same system. This allows risk assessors to quickly review what data sources and
            tools are available for given purposes and to have guidance on their appropriate use
            using a tiering system. In parallel with this database, a report was developed providing
            analysis, discussion and case studies demonstrating different uses of some the different
            tools and data sources (available on the main ECETOC website). There are hundreds of
            identified sources of human exposure data and tools in heatDB.
          </p>
        </div>
      </section>
      <section className="section2">
        <div className="width-wrapper">
          <div>
            <p className="icon">
              <Tools />
            </p>
            <p className="value">316</p>
            <p>Data Sources</p>
          </div>
          <div>
            <p>
              <DataSources />
            </p>
            <p className="value">69</p>
            <p>Tools</p>
          </div>
        </div>
      </section>

      <section className="section3">
        <div className="width-wrapper" ref={RegisterFormRef}>
          <h2>Register for free</h2>
          <p className="bold-p">
            You can register for free, login and use the database as required. Leave comments or
            feedback on the message board on how we can improve the database and identify new data
            sources.
          </p>
          <RegisterForm />
        </div>
      </section>
      <footer>
        <div className="width-wrapper">
          <h2>Thank you for your interest in heatDB.</h2>
          <p className="bold-p">
            <span> Please send any questions, queries and comments to</span> heatdb@cremeglobal.com
          </p>
          <p className="footer-link">
            <a href="https://www.ecetoc.org/privacy-notice/">ECETOC Privacy Policy</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Landing;
