import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { isLoggedIn } from './services/localStorage.service';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Faq from './pages/Faq/Faq';
import Dashboard from './pages/Dashboard/Dashboard';
import Activation from './pages/Activation/Activation';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import './App.scss';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#01C4A5',
      contrastText: '#fff',
    },
    secondary: {
      main: '#BEF0E6',
    },
  },
});

function App() {
  const loggedIn = isLoggedIn();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div id="ecetoc">
          <Switch>
            <Route path="/activation/:token">
              <Activation />
            </Route>
            <Route path="/login">{loggedIn ? <Redirect to="/dashboard" /> : <Login />}</Route>
            <Route path="/signup">{loggedIn ? <Redirect to="/dashboard" /> : <Signup />}</Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-pwd/:token">
              <ResetPassword />
            </Route>
            <Route path="/Faq">{!loggedIn ? <Redirect to="/login" /> : <Faq />}</Route>
            <Route path="/dashboard">{!loggedIn ? <Redirect to="/login" /> : <Dashboard />}</Route>
            <Route path="/">
              <Landing />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
