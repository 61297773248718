import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import DashboardFooter from '../../components/DashboardFooter/DashboardFooter';
import DataItem from '../../components/DataItem/DataItem';
import ToolItem from '../../components/ToolItem/ToolItem';
import Pagination from '../../components/Pagination/Pagination';
import Filters from '../../components/Filters/Filters';
import { fetchData } from '../../services/api.service';
import { setFilteredData } from '../../store/reducers/dataReducer';
import { applyFilters } from '../../services/filters';

import './Dashboard.scss';

function Dashboard() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.dataReducer.data);
  const filteredData = useSelector(state => state.dataReducer.filteredData);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(20);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (!data.rows.length && !dataFetched) {
      fetchData();
      setDataFetched(true);
    }
  }, [data, dataFetched]);

  useEffect(() => {
    if (data.rows.length) {
      dispatch(setFilteredData(data.rows.map(e => ({ ...e }))));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data.rows.length && !keys.length) {
      setKeys([...Object.keys(data.dataMetadata), ...Object.keys(data.toolsMetadata)]);
    }
  }, [data, keys]);

  const filterData = useCallback(
    filtersObj => {
      dispatch(setFilteredData(applyFilters(data.rows, keys, filtersObj)));
    },
    [data, keys, dispatch]
  );

  return (
    <div id="dashboard">
      <DashboardHeader />
      <div className="section-width-wrapper">
        <h2>Exposure Databases and Tools</h2>
        <p className="small-p">
          This collection represents the exposure databases and tools deemed to be of high quality
          and recommended for use by ECETOC. This list is curated by ECETOC.
        </p>
        <Filters filterData={filterData} />
        {data.loading ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <div className="data-list">
            {filteredData
              .slice((page - 1) * offset, page * offset)
              .map(d =>
                d.desc === 'data' ? (
                  <DataItem key={d._id} item={d} />
                ) : (
                  <ToolItem key={d._id} item={d} />
                )
              )}
            <Pagination
              page={page}
              offset={offset}
              total={filteredData.length}
              onOffsetChange={setOffset}
              onPageChange={setPage}
            />
          </div>
        )}
      </div>
      <DashboardFooter />
    </div>
  );
}

export default Dashboard;
