export const search = (data, keys, term) => {
  const re = new RegExp(term.replace(/([.?*+^$[\]\\(){}|-])/g, '//$1'), 'ig');

  return [
    ...data
      .filter(e => {
        for (let i = 0; i < keys.length; i++) {
          if ((e[keys[i]] || '').toLowerCase().indexOf(term.toLowerCase()) !== -1) {
            return true;
          }
        }
        return false;
      })
      .map(e => {
        let newElmt = { ...e };
        for (let i = 0; i < keys.length; i++) {
          if (newElmt[keys[i]]) {
            // replacing matched word while keeping case
            newElmt[keys[i]] = newElmt[keys[i]].replace(re, w =>
              [...('<span class="match">' + term.toLowerCase() + '</span>')]
                .map((c, i) =>
                  i < 20 || i > 20 + w.length || !w[i - 20]
                    ? c
                    : w[i - 20] === w[i - 20].toLowerCase()
                    ? c
                    : c.toUpperCase()
                )
                .join('')
            );
          }
        }
        return newElmt;
      }),
  ];
};

export const applyFilters = (data, keys, filter) => {
  // let filteredData = data.map(e => ({ ...e }));
  let filteredData = [...data];
  // filter by type
  if (filter.type && filter.type !== 'all') {
    filteredData = filteredData.filter(e => e.desc === filter.type);
  }

  // filter by category
  if (filter.filter && filter.categories.length) {
    filteredData = filteredData.filter(e => {
      for (let i = 0; i < filter.categories.length; i++) {
        if ((e[filter.filter] || '').toLowerCase().indexOf(filter.categories[i]) !== -1) {
          return true;
        }
      }
      return false;
    });
  }

  // filter by term
  return filter.term.trim() !== '' ? search(filteredData, keys, filter.term) : filteredData;
};
