import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageFormLayout from '../../components/PageFormLayout/PageFormLayout';
import { activate } from '../../services/api.service';

function Activation() {
  let { token } = useParams();
  const activateState = useSelector(state => state.formReducer.activate);
  console.log('activateState: ', activateState);
  useEffect(() => {
    activate(token);
  }, [token]);

  return (
    <PageFormLayout>
      <h2 className="card-title">Account Activation</h2>
      {activateState.loading && <p>Activating your account...</p>}
      {activateState.success && <p>Happy Days, your account has been activated.</p>}
      {activateState.error && <p>{activateState.error}</p>}

      <div className="action-links">
        <Link className="underlined" to="/login">
          login
        </Link>
        <Link to="signup">Register</Link>
      </div>
    </PageFormLayout>
  );
}

export default Activation;
