import { getToken, removeToken, saveToken } from './localStorage.service';
import {
  setLoginLoading,
  setLoginError,
  setRegisterError,
  setRegisterLoading,
  setRegisterSuccess,
  setActivateError,
  setActivateSuccess,
  setActivateLoading,
  setForgotError,
  setForgotLoading,
  setForgotSuccess,
  setResetError,
  setResetLoading,
  setResetSuccess,
} from '../store/reducers/formReducer';
import {
  setDataLoading,
  setDataError,
  setData,
  setFAQsLoading,
  setFAQsError,
  setFAQs,
} from '../store/reducers/dataReducer';
import { store } from '../store/store';

const API_URL = 'https://heatdb.cremeglobal.com/api';

export const authenticate = async credentials => {
  try {
    store.dispatch(setLoginError(''));
    store.dispatch(setLoginLoading(true));
    const res = await fetch(API_URL + '/authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    const response = await res.json();
    if (response.token) {
      saveToken(response.token);
      store.dispatch(setLoginLoading(false));
    } else {
      handleLoginError(response.message || 'An error occurred, please try again!');
      return;
    }
    // redirect to dashboard
    document.location.href = '/dashboard';
  } catch (e) {
    console.error(e);
    handleLoginError('An error occurred, please try again!');
  }
};

const handleLoginError = message => {
  store.dispatch(setLoginLoading(false));
  store.dispatch(setLoginError(message));
};

export const register = async data => {
  try {
    store.dispatch(setRegisterError(''));
    store.dispatch(setRegisterLoading(true));
    const res = await fetch(API_URL + '/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const response = await res.json();
    if (response.message === 'User created!') {
      store.dispatch(setRegisterLoading(false));
      store.dispatch(setRegisterSuccess(true));
    } else {
      handleRegisterError(response.message || 'An error occurred, please try again!');
    }
  } catch (e) {
    console.error(e);
    handleRegisterError('An error occurred, please try again!');
  }
};

const handleRegisterError = message => {
  store.dispatch(setRegisterLoading(false));
  store.dispatch(setRegisterError(message));
};

export const activate = async token => {
  try {
    store.dispatch(setActivateError(''));
    store.dispatch(setActivateLoading(true));
    const res = await fetch(API_URL + '/activate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    const response = await res.json();
    if (response.message.indexOf('success') !== -1) {
      store.dispatch(setActivateSuccess(true));
    } else {
      store.dispatch(setActivateError(response.message || 'An error occurred, please try again!'));
    }
  } catch (e) {
    console.error(e);
    store.dispatch(setActivateError('An error occurred, please try again!'));
  }
};

export const forgot = async email => {
  try {
    store.dispatch(setForgotError(''));
    store.dispatch(setForgotLoading(true));
    const res = await fetch(API_URL + '/password/forgot/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const response = await res.json();
    if (response.message.indexOf('password') !== -1) {
      store.dispatch(setForgotSuccess(true));
    } else {
      store.dispatch(setForgotError(response.message || 'An error occurred, please try again!'));
    }
  } catch (e) {
    console.error(e);
    store.dispatch(setForgotError('An error occurred, please try again!'));
  }
};

export const reset = async data => {
  try {
    store.dispatch(setResetError(''));
    store.dispatch(setResetLoading(true));
    const res = await fetch(API_URL + '/password/reset/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const response = await res.json();
    if (response.message.indexOf('reset') !== -1) {
      store.dispatch(setResetSuccess(true));
    } else {
      store.dispatch(setResetError(response.message || 'An error occurred, please try again!'));
    }
  } catch (e) {
    console.error(e);
    store.dispatch(setResetError('An error occurred, please try again!'));
  }
};

export const fetchData = async () => {
  const token = getToken();
  if (!token) {
    document.location.href = '/login';
    return;
  }
  store.dispatch(setDataLoading(true));
  store.dispatch(setDataError(''));
  let res = await fetch(API_URL + '/data/datasources', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token.replaceAll('"', ''),
    },
  });
  const dataRes = await res.json();
  if (dataRes.message === 'Failed to authenticate token') {
    removeToken();
    document.location.href = '/login';
    return;
  }
  if (!dataRes.metadata || !dataRes.rows) {
    store.dispatch(setDataError(dataRes.message || 'An error occurred, please try again!'));
    return;
  }
  res = await fetch(API_URL + '/data/tools', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token.replaceAll('"', ''),
    },
  });
  const toolsRes = await res.json();
  if (toolsRes.message === 'Failed to authenticate token') {
    removeToken();
    document.location.href = '/login';
    return;
  }
  if (!toolsRes.metadata || !toolsRes.rows) {
    store.dispatch(setDataError(dataRes.message || 'An error occurred, please try again!'));
    return;
  }
  store.dispatch(setDataLoading(false));
  store.dispatch(
    setData({
      dataMetadata: dataRes.metadata,
      toolsMetadata: toolsRes.metadata,
      rows: [
        ...dataRes.rows.map(e => ({ ...e, desc: 'data' })),
        ...toolsRes.rows.map(e => ({ ...e, desc: 'tool' })),
      ],
    })
  );
};

export const fetchFAQs = async () => {
  const token = getToken();
  if (!token) {
    document.location.href = '/login';
    return;
  }
  store.dispatch(setFAQsLoading(true));
  store.dispatch(setFAQsError(''));
  let res = await fetch(API_URL + '/faqs', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token.replaceAll('"', ''),
    },
  });
  const faqs = await res.json();
  if (faqs.message === 'Failed to authenticate token') {
    removeToken();
    document.location.href = '/login';
    return;
  }
  if (!faqs.length) {
    store.dispatch(setFAQsError(faqs.message || 'An error occurred, please try again!'));
    return;
  }
  store.dispatch(setFAQsLoading(false));
  store.dispatch(setFAQs(faqs));
};
