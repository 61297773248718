const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';
const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
const SET_REGISTER_LOADING = 'SET_REGISTER_LOADING';
const SET_REGISTER_ERROR = 'SET_REGISTER_ERROR';
const SET_REGISTER_SUCCESS = 'SET_REGISTER_SUCCESS';
const SET_ACTIVATE_SUCCESS = 'SET_ACTIVATE_SUCCESS';
const SET_ACTIVATE_ERROR = 'SET_ACTIVATE_ERROR';
const SET_ACTIVATE_LOADING = 'SET_ACTIVATE_LOADING';
const SET_RESET_SUCCESS = 'SET_RESET_SUCCESS';
const SET_RESET_ERROR = 'SET_RESET_ERROR';
const SET_RESET_LOADING = 'SET_RESET_LOADING';
const SET_FORGOT_SUCCESS = 'SET_FORGOT_SUCCESS';
const SET_FORGOT_ERROR = 'SET_FORGOT_ERROR';
const SET_FORGOT_LOADING = 'SET_FORGOT_LOADING';

export const setLoginLoading = data => {
  return {
    type: SET_LOGIN_LOADING,
    payload: data,
  };
};
export const setLoginError = data => {
  return {
    type: SET_LOGIN_ERROR,
    payload: data,
  };
};
export const setRegisterLoading = data => {
  return {
    type: SET_REGISTER_LOADING,
    payload: data,
  };
};
export const setRegisterError = data => {
  return {
    type: SET_REGISTER_ERROR,
    payload: data,
  };
};
export const setRegisterSuccess = data => {
  return {
    type: SET_REGISTER_SUCCESS,
    payload: data,
  };
};
export const setActivateSuccess = data => {
  return {
    type: SET_ACTIVATE_SUCCESS,
    payload: data,
  };
};
export const setActivateError = data => {
  return {
    type: SET_ACTIVATE_ERROR,
    payload: data,
  };
};
export const setActivateLoading = data => {
  return {
    type: SET_ACTIVATE_LOADING,
    payload: data,
  };
};
export const setResetSuccess = data => {
  return {
    type: SET_RESET_SUCCESS,
    payload: data,
  };
};
export const setResetError = data => {
  return {
    type: SET_RESET_ERROR,
    payload: data,
  };
};
export const setResetLoading = data => {
  return {
    type: SET_RESET_LOADING,
    payload: data,
  };
};
export const setForgotSuccess = data => {
  return {
    type: SET_FORGOT_SUCCESS,
    payload: data,
  };
};
export const setForgotError = data => {
  return {
    type: SET_FORGOT_ERROR,
    payload: data,
  };
};
export const setForgotLoading = data => {
  return {
    type: SET_FORGOT_LOADING,
    payload: data,
  };
};

const defaultState = {
  loginForm: {
    loading: false,
    error: '',
  },
  registerForm: {
    loading: false,
    error: '',
    success: false,
  },
  activate: {
    loading: false,
    error: '',
    success: false,
  },
  reset: {
    loading: false,
    error: '',
    success: false,
  },
  forgot: {
    loading: false,
    error: '',
    success: false,
  },
};

const formReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOGIN_LOADING:
      state.loginForm = { ...state.loginForm, loading: action.payload };
      return { ...state };
    case SET_LOGIN_ERROR:
      state.loginForm = { ...state.loginForm, error: action.payload };
      return { ...state };
    case SET_REGISTER_LOADING:
      state.registerForm = { ...state.registerForm, loading: action.payload };
      return { ...state };
    case SET_REGISTER_ERROR:
      state.registerForm = { ...state.registerForm, error: action.payload };
      return { ...state };
    case SET_REGISTER_SUCCESS:
      state.registerForm = { ...state.registerForm, success: action.payload };
      return { ...state };
    case SET_ACTIVATE_SUCCESS:
      state.activate = { ...state.activate, success: action.payload, loading: false };
      return { ...state };
    case SET_ACTIVATE_ERROR:
      state.activate = { ...state.activate, error: action.payload, loading: false };
      return { ...state };
    case SET_ACTIVATE_LOADING:
      state.activate = { ...state.activate, loading: action.payload };
      return { ...state };
    case SET_FORGOT_SUCCESS:
      state.forgot = { ...state.forgot, success: action.payload, loading: false };
      return { ...state };
    case SET_FORGOT_ERROR:
      state.forgot = { ...state.forgot, error: action.payload, loading: false };
      return { ...state };
    case SET_FORGOT_LOADING:
      state.forgot = { ...state.forgot, loading: action.payload };
      return { ...state };
    case SET_RESET_SUCCESS:
      state.reset = { ...state.reset, success: action.payload, loading: false };
      return { ...state };
    case SET_RESET_ERROR:
      state.reset = { ...state.reset, error: action.payload, loading: false };
      return { ...state };
    case SET_RESET_LOADING:
      state.reset = { ...state.reset, loading: action.payload };
      return { ...state };
    default:
      return state;
  }
};

export default formReducer;
