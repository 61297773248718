import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import TimelineIcon from '@material-ui/icons/Timeline';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import './Filters.scss';

let debounceTimer = null;

const filtersObject = [
  {
    value: 'Data - Product Category',
    type: 'data',
    key: 'product_category',
    subFilters: [
      { value: 'Cosmetics and Personal Care' },
      { value: 'Environmental Exposure' },
      { value: 'Foods' },
      { value: 'Generic Human Exposure Factors' },
      { value: 'Household Products' },
      { value: 'Other Consumer Products' },
      { value: 'Personal Care Products' },
      { value: 'Tobacco and Food' },
      { value: 'Washing Agents' },
      { value: 'n/a' },
    ],
  },
  {
    value: 'Data - Data Category',
    type: 'data',
    key: 'category_of_data',
    subFilters: [
      { value: 'Biomonitoring Data' },
      { value: 'Chemical Concentration Data' },
      { value: 'Chemical Occurrence Data' },
      { value: 'Co-Use Data' },
      { value: 'Exposure Algorithms' },
      { value: 'Habits and Practices Data' },
      { value: 'n/a' },
    ],
  },
  {
    value: 'Tools - Product Category',
    type: 'tool',
    key: 'product_category',
    subFilters: [
      { value: 'Articles' },
      { value: 'Foods' },
      { value: 'Cosmetics' },
      { value: 'Biocides' },
      { value: 'Household Products' },
      { value: 'Indoor Environment' },
      { value: 'Other' },
      { value: 'n/a' },
    ],
  },
  {
    value: 'Tools - Regions Covered',
    type: 'tool',
    key: 'regions',
    subFilters: [
      { value: 'Americas' },
      { value: 'Asia' },
      { value: 'Australia and New Zealand' },
      { value: 'Canada' },
      { value: 'EU' },
      { value: 'Europe' },
      { value: 'Universal' },
      { value: 'US' },
      { value: 'n/a' },
    ],
  },
  {
    value: 'Tier',
    type: 'all',
    key: 'tier',
    subFilters: [{ value: 'Tier 1' }, { value: 'Tier 2' }, { value: 'Tier 3' }],
  },
];

function Filters({ filterData }) {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState('all');
  const [filterObj, setFilterObj] = useState({
    term: '',
    type: 'all',
    filter: '',
    categories: [],
  });

  useEffect(() => {
    filterData(filterObj);
  }, [filterData, filterObj]);

  const updateSearch = e => {
    setSearch(e.target.value);
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilterObj({ ...filterObj, term: e.target.value });
    }, 100);
  };

  const clearSearch = () => {
    setSearch('');
    setFilterObj({ ...filterObj, term: '' });
  };

  const clearFilters = () => {
    setFilter('');
    setCategories([]);
    setType('all');
    setFilterObj({
      term: '',
      type: 'all',
      filter: '',
      categories: [],
    });
  };

  const updateFilter = e => {
    setFilter(e.target.value);
    setCategories([]);
    setType(filtersObject[e.target.value].type);
    setFilterObj({
      ...filterObj,
      filter: filtersObject[e.target.value].key,
      type: filtersObject[e.target.value].type,
      categories: [],
    });
  };

  const updateCategories = e => {
    setCategories(e.target.value);
    setFilterObj({ ...filterObj, categories: e.target.value });
  };

  const updateType = e => {
    setType(e.target.value);
    if (filter !== 4) {
      setFilter('');
      setCategories([]);
      setFilterObj({ ...filterObj, filter: '', type: e.target.value, categories: [] });
    } else {
      setFilterObj({ ...filterObj, type: e.target.value });
    }
  };

  return (
    <div className="filters">
      <div className="search">
        <TextField
          variant="outlined"
          placeholder="Enter search term"
          value={search}
          onChange={updateSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment:
              search !== '' ? (
                <InputAdornment position="end">
                  <CloseIcon className="clickable" onClick={clearSearch} />
                </InputAdornment>
              ) : null,
          }}
        />
        <Button variant="contained" color="primary" disableElevation>
          Search
        </Button>
      </div>
      <div className="selects">
        <div className="filter-1">
          <FormControl variant="outlined">
            <InputLabel id="filter-1">Filter by</InputLabel>
            <Select labelId="filter-1" label="Filter by" value={filter} onChange={updateFilter}>
              {filtersObject.map((v, i) => (
                <MenuItem value={i} key={v.value}>
                  {v.value}
                  {v.type === 'data' ? (
                    <TimelineIcon />
                  ) : v.type === 'tool' ? (
                    <ControlCameraIcon />
                  ) : (
                    <Tooltip title="The level of refinement of a risk assessment, starting from Tier 1 (deterministic screening level calculations), Tier 2 refined analysis with more realistic parameters) and Tier 3 (population based and/or probabilistic assessment).">
                      <InfoIcon />
                    </Tooltip>
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={`filter-2 ${filter === '' ? 'disabled' : ''}`}>
          <FormControl variant="outlined">
            <InputLabel id="filter-2">Select Category</InputLabel>
            <Select
              labelId="filter-2"
              label="Select Category"
              multiple
              value={categories}
              disabled={filter === ''}
              onChange={updateCategories}
              renderValue={selected => selected.join(', ')}
            >
              {filtersObject[filter]?.subFilters.map(v => (
                <MenuItem value={v.value.toLowerCase()} key={v.value}>
                  <Checkbox
                    checked={categories.indexOf(v.value.toLowerCase()) > -1}
                    color="primary"
                  />
                  <ListItemText primary={v.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Button
          variant="contained"
          color="secondary"
          disableElevation
          disabled={filter === ''}
          onClick={clearFilters}
        >
          Clear Filters
        </Button>
      </div>
      <div className="type">
        <FormControl component="fieldset">
          <RadioGroup aria-label="type" name="type" value={type} onChange={updateType}>
            <FormControlLabel value="all" control={<Radio color="primary" />} label="Show All" />
            <FormControlLabel value="data" control={<Radio color="primary" />} label="Show Data" />
            <FormControlLabel value="tool" control={<Radio color="primary" />} label="Show Tools" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default Filters;
