import { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimelineIcon from '@material-ui/icons/Timeline';
import InfoIcon from '@material-ui/icons/Info';
import ReactGA from 'react-ga';

import './DataItem.scss';

function DataItem({ item }) {
  const [expanded, setExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleExpandStatus = () => {
    if (!expanded) {
      const category = item.public_sources || '';
      const action = 'View Data Details';
      const label = item._id;
      ReactGA.event({category, action, label});
    }
    setExpanded(!expanded);
  };

  const onHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onMouseEnter={onHover} onMouseLeave={onHover} />}
        aria-controls={'data-' + item._id}
        id={'data-' + item._id}
        onClick={toggleExpandStatus}
      >
        <div className="summary">
          <div className="expand-indicator" style={{ display: isHovered ? 'block' : 'none' }}>
            {expanded ? 'Close' : 'Expand'}
          </div>
          <div className="kind">
            <TimelineIcon /> Data
          </div>
          <h4>Public Source of Exposure Data</h4>
          <p dangerouslySetInnerHTML={{ __html: item.public_sources }} />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="summary">
          <h4>Type of Exposure Data</h4>
          <p dangerouslySetInnerHTML={{ __html: item.type || 'Not available' }} />
          <h4>Product Category</h4>
          <p dangerouslySetInnerHTML={{ __html: item.product_category || 'Not available' }} />
          <h4>Category of Data</h4>
          <p dangerouslySetInnerHTML={{ __html: item.category_of_data || 'Not available' }} />
          <h4>Comment on Data Sufficiency</h4>
          <p dangerouslySetInnerHTML={{ __html: item.comment || 'Not available' }} />
          <h4>Source</h4>
          {item.source && item.source.indexOf('http') === 0 ? (
            <p>
              <a
                href={item.source}
                target="_blank"
                rel="noreferrer"
                dangerouslySetInnerHTML={{ __html: item.source }}
              ></a>
            </p>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: item.source || 'Not available' }} />
          )}
          <h4 className="hasIcon">
            Tier
            <Tooltip title="The level of refinement of a risk assessment, starting from Tier 1 (deterministic screening level calculations), Tier 2 refined analysis with more realistic parameters) and Tier 3 (population based and/or probabilistic assessment).">
              <InfoIcon />
            </Tooltip>
          </h4>
          <p dangerouslySetInnerHTML={{ __html: item.tier || 'Not available' }} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default DataItem;
