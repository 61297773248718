import { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import './Pagination.scss';

function Pagination({ page, offset, total, onOffsetChange, onPageChange }) {
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    setMaxPage(Math.ceil(total / offset));
    onPageChange(1);
  }, [offset, total, onPageChange]);

  const onFirstPage = () => {
    onPageChange(1);
  };

  const onLastPage = () => {
    onPageChange(maxPage);
  };

  const onPrevPage = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const onNextPage = () => {
    if (page < maxPage) {
      onPageChange(page + 1);
    }
  };

  return (
    <div className="pagination">
      <div className="views">
        <span className="rpp">Rows per page</span>
        <FormControl variant="outlined">
          <Select value={offset} onChange={e => onOffsetChange(e.target.value)}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <span className="current">
          {(page - 1) * offset + 1}-{page * offset > total ? total : page * offset} of {total}
        </span>
      </div>
      <span className="controls">
        <FirstPageIcon onClick={onFirstPage} className={page === 1 ? 'disabled' : ''} />
        <KeyboardArrowLeftIcon onClick={onPrevPage} className={page === 1 ? 'disabled' : ''} />
        <KeyboardArrowRightIcon
          onClick={onNextPage}
          className={page === maxPage ? 'disabled' : ''}
        />
        <LastPageIcon onClick={onLastPage} className={page === maxPage ? 'disabled' : ''} />
      </span>
    </div>
  );
}

export default Pagination;
