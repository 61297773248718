import { Link } from 'react-router-dom';

import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg';
import './Logo.scss';

function Logo() {
  return (
    <Link to="/landing">
      <LogoSVG />
    </Link>
  );
}

export default Logo;
