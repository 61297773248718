import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Logo from '../Logo/Logo';
import './PageFormLayout.scss';

function PageFormLayout(props) {
  return (
    <div className="bg-page-container">
      <div className="header-logo">
        <Logo />
      </div>
      <Card className="card">
        <CardContent>
          <div className="card-width-wrapper">{props.children}</div>
        </CardContent>
      </Card>
    </div>
  );
}

export default PageFormLayout;
