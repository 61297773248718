import { createStore, combineReducers } from 'redux';
import formReducer from './reducers/formReducer';
import dataReducer from './reducers/dataReducer';

export const store = createStore(
  combineReducers({
    formReducer,
    dataReducer,
  })
);
