import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import PageFormLayout from '../../components/PageFormLayout/PageFormLayout';
import { authenticate } from '../../services/api.service';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const loginForm = useSelector(state => state.formReducer.loginForm);

  const handleSubmit = () => {
    authenticate({ username, password });
  };

  return (
    <PageFormLayout>
      <h2 className="card-title">Login to heatDB</h2>
      <div className="form">
        <ValidatorForm onSubmit={handleSubmit}>
          <TextValidator
            label="Your Email"
            variant="outlined"
            validators={['required', 'isEmail']}
            errorMessages={['Email is required', 'Email is not valid']}
            onChange={e => setUsername(e.target.value.trim())}
            value={username}
          />
          <TextValidator
            label="Your Password"
            variant="outlined"
            type="password"
            validators={['required']}
            errorMessages={['Password is required']}
            onChange={e => setPassword(e.target.value.trim())}
            value={password}
          />

          <div className="action-buttons">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              disabled={loginForm.loading}
            >
              {loginForm.loading ? <CircularProgress size={28} /> : 'Login'}
            </Button>
            {loginForm.error && (
              <p className="MuiFormHelperText-root Mui-error">{loginForm.error}</p>
            )}
          </div>
        </ValidatorForm>
      </div>
      <div className="action-links">
        <Link className="underlined" to="/forgot-password">
          Forgot Password?
        </Link>
        <Link to="signup">Register</Link>
      </div>
    </PageFormLayout>
  );
}

export default Login;
