import { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ReactGA from 'react-ga';

import '../DataItem/DataItem.scss';

function ToolItem({ item }) {
  const [expanded, setExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleExpandStatus = () => {
    if (!expanded) {
      const category = item.available_tools || '';
      const action = 'View Tool Details';
      const label = item._id;
      ReactGA.event({category, action, label});
    }
    setExpanded(!expanded);
  };

  const onHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onMouseEnter={onHover} onMouseLeave={onHover} />}
        aria-controls={'tool-' + item._id}
        id={'tool-' + item._id}
        onClick={toggleExpandStatus}
      >
        <div className="expand-indicator" style={{ display: isHovered ? 'block' : 'none' }}>
          {expanded ? 'Close' : 'Expand'}
        </div>
        <div className="summary">
          <div className="kind">
            <ControlCameraIcon /> Tool
          </div>
          <h4>Available Exposure Tools</h4>
          <p dangerouslySetInnerHTML={{ __html: item.available_tools }} />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="summary">
          <h4>Type of Assessment that can be done e.g. single product, aggregate</h4>
          <p dangerouslySetInnerHTML={{ __html: item.type_of_assessment || 'Not available' }} />
          <h4>Product Category</h4>
          <p dangerouslySetInnerHTML={{ __html: item.product_category || 'Not available' }} />
          <h4>Regions Covered</h4>
          <p dangerouslySetInnerHTML={{ __html: item.regions || 'Not available' }} />
          <h4>Routes of Exposure Covered</h4>
          <p dangerouslySetInnerHTML={{ __html: item.routes || 'Not available' }} />
          <h4>Occupational or Consumer</h4>
          <p
            dangerouslySetInnerHTML={{ __html: item.occupational_or_consumer || 'Not available' }}
          />
          <h4>Availability</h4>
          <p dangerouslySetInnerHTML={{ __html: item.availability || 'Not available' }} />
          <h4>Built-in Data/Data Requirements</h4>
          <p dangerouslySetInnerHTML={{ __html: item.data_requirements || 'Not available' }} />
          <h4>Modelling Capabilities</h4>
          <p dangerouslySetInnerHTML={{ __html: item.modelling_capabilities || 'Not available' }} />
          <h4 className="hasIcon">
            Tier
            <Tooltip title="The level of refinement of a risk assessment, starting from Tier 1 (deterministic screening level calculations), Tier 2 refined analysis with more realistic parameters) and Tier 3 (population based and/or probabilistic assessment).">
              <InfoIcon />
            </Tooltip>
          </h4>
          <p dangerouslySetInnerHTML={{ __html: item.tier || 'Not available' }} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default ToolItem;
