import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import DashboardFooter from '../../components/DashboardFooter/DashboardFooter';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import { fetchFAQs } from '../../services/api.service';

import './Faq.scss';

function Faq() {
  const faqs = useSelector(state => state.dataReducer.faqs);
  const [FAQsFetched, setFAQsFetched] = useState(false);

  useEffect(() => {
    if (!faqs.rows.length && !FAQsFetched) {
      fetchFAQs();
      setFAQsFetched(true);
    }
  }, [faqs, FAQsFetched]);

  return (
    <div id="dashboard" className="faqs">
      <DashboardHeader />
      <div className="section-width-wrapper">
        <h2>FAQ</h2>
        <p className="small-p">
          Answers to these commonly asked questions may help you get the most value out of using
          heatDB tool. Contact us directly if you have questions not covered here.
        </p>
        {faqs.loading ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          faqs.rows.map(q => (
            <div className="question" key={q._id}>
              <h3>{q.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: q.message }} />
            </div>
          ))
        )}
      </div>
      <DashboardFooter />
    </div>
  );
}

export default Faq;
