import { Link } from 'react-router-dom';
import { useState } from 'react';
import { ReactComponent as LogoSVG } from '../../assets/images/logo-white.svg';
import { removeToken } from '../../services/localStorage.service';
import DehazeIcon from '@material-ui/icons/Dehaze';
import CloseIcon from '@material-ui/icons/Close';

import './DashboardHeader.scss';

function DashboardHeader() {
  const handleLogout = e => {
    e.preventDefault();
    removeToken();
    document.location.href = '/';
  };

  const [menuOpened, toggleMenu] = useState(false);

  const onMenuToggled = () => {
    toggleMenu(!menuOpened);
  };

  return (
    <header>
      <div className="header-width-wrapper">
        <div className="header-logo">
          <Link to="/dashboard">
            <LogoSVG />
          </Link>
        </div>
        <div className="faq-links">
          <Link className="faq" to={window.location.pathname === '/faq' ? '/dashboard' : 'faq'}>
            {window.location.pathname === '/faq' ? 'DATABASE' : 'FAQ'}
          </Link>
          <Link className="sign-out" to="/logout" onClick={handleLogout}>
            SIGN OUT
          </Link>
        </div>
        <div className="faq-links-mobile">
          {menuOpened ? (
            <div className="links-mobile">
              <CloseIcon onClick={onMenuToggled} />
              <Link className="sign-out" to="/home">
                HOME
              </Link>
              <Link className="sign-out" to="/dashboard">
                DASHBOARD
              </Link>
              <Link className="faq" to="faq" onClick={onMenuToggled}>
                FAQ
              </Link>
              <Link className="sign-out" to="/logout" onClick={handleLogout}>
                SIGN OUT
              </Link>
            </div>
          ) : (
            <DehazeIcon onClick={onMenuToggled} />
          )}
        </div>
      </div>
    </header>
  );
}

export default DashboardHeader;
