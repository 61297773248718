const SET_DATA_LOADING = 'SET_DATA_LOADING';
const SET_DATA_ERROR = 'SET_DATA_ERROR';
const SET_DATA = 'SET_DATA';
const SET_FILTERED_DATA = 'SET_FILTERED_DATA';
const SET_FAQS = 'SET_FAQS';
const SET_FAQS_LOADING = 'SET_FAQS_LOADING';
const SET_FAQS_ERROR = 'SET_FAQS_ERROR';

export const setDataLoading = data => {
  return {
    type: SET_DATA_LOADING,
    payload: data,
  };
};
export const setDataError = data => {
  return {
    type: SET_DATA_ERROR,
    payload: data,
  };
};
export const setData = data => {
  return {
    type: SET_DATA,
    payload: data,
  };
};
export const setFAQsLoading = data => {
  return {
    type: SET_FAQS_LOADING,
    payload: data,
  };
};
export const setFAQsError = data => {
  return {
    type: SET_FAQS_ERROR,
    payload: data,
  };
};
export const setFAQs = data => {
  return {
    type: SET_FAQS,
    payload: data,
  };
};
export const setFilteredData = data => {
  return {
    type: SET_FILTERED_DATA,
    payload: data,
  };
};

const defaultState = {
  faqs: {
    loading: false,
    error: '',
    rows: [],
  },
  data: {
    loading: false,
    error: '',
    dataMetadata: null,
    toolsMetadata: null,
    rows: [],
  },
  filteredData: [],
};

const dataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DATA_LOADING:
      state.data = { ...state.data, loading: action.payload };
      return { ...state };
    case SET_DATA_ERROR:
      state.data = { ...state.data, error: action.payload };
      return { ...state };
    case SET_DATA:
      state.data = {
        ...state.data,
        dataMetadata: action.payload.dataMetadata,
        toolsMetadata: action.payload.toolsMetadata,
        rows: action.payload.rows,
      };
      return { ...state };
    case SET_FAQS_LOADING:
      state.faqs = { ...state.faqs, loading: action.payload };
      return { ...state };
    case SET_FAQS_ERROR:
      state.faqs = { ...state.faqs, error: action.payload };
      return { ...state };
    case SET_FAQS:
      state.faqs = { ...state.data, rows: action.payload };
      return { ...state };
    case SET_FILTERED_DATA:
      state.filteredData = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default dataReducer;
