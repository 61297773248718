import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import PageFormLayout from '../../components/PageFormLayout/PageFormLayout';
import { forgot } from '../../services/api.service';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const forgotState = useSelector(state => state.formReducer.forgot);

  const handleSubmit = () => {
    forgot(email);
  };

  return (
    <PageFormLayout>
      <h2 className="card-title">Reset Password</h2>
      {forgotState.success ? (
        <p className="register-success">
          An email was sent to the provided email address with instructions to reset your password.
        </p>
      ) : (
        <div>
          <p>Enter your email and we’ll send you a password reset link.</p>
          <div className="form">
            <ValidatorForm onSubmit={handleSubmit}>
              <TextValidator
                label="Your Email"
                variant="outlined"
                validators={['required', 'isEmail']}
                errorMessages={['Email is required', 'Email is not valid']}
                onChange={e => setEmail(e.target.value.trim())}
                value={email}
              />
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="submit"
                  disabled={forgotState.loading}
                >
                  {forgotState.loading ? <CircularProgress size={28} /> : 'Reset'}
                </Button>
                {forgotState.error && (
                  <p className="MuiFormHelperText-root Mui-error">{forgotState.error}</p>
                )}
              </div>
            </ValidatorForm>
          </div>
        </div>
      )}

      <div className="action-links">
        <Link className="underlined" to="/login">
          login
        </Link>
        <Link to="signup">Register</Link>
      </div>
    </PageFormLayout>
  );
}

export default ForgotPassword;
