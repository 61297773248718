function DashboardFooter() {
  return (
    <div className="footer">
      <h3>Contribute to Ecetoc heatDB</h3>
      <p>
        Submit a database or tool to include in heatDB through our online form{'  '}
        <a href="https://forms.gle/Xmqn5d2yX5tj1PgRA" target="_blank" rel="noreferrer">
          online form
        </a>
        .
      </p>
    </div>
  );
}

export default DashboardFooter;
