import { Link } from 'react-router-dom';
import PageFormLayout from '../../components/PageFormLayout/PageFormLayout';
import RegisterForm from '../../components/RegisterForm/RegisterForm';

function Signup() {
  return (
    <PageFormLayout>
      <h2 className="card-title">Register for heatDB</h2>
      <RegisterForm stepped />
      <div className="action-links">
        <Link className="underlined" to="/login">
          Login
        </Link>
        <a href="https://www.ecetoc.org/privacy-notice/">Privacy Policy</a>
      </div>
    </PageFormLayout>
  );
}

export default Signup;
