import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { register } from '../../services/api.service';
import { setRegisterSuccess } from '../../store/reducers/formReducer';

function RegisterForm({ stepped }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [step, setStep] = useState(1);
  const registerForm = useSelector(state => state.formReducer.registerForm);

  useEffect(() => {
    dispatch(setRegisterSuccess(false));
  }, [dispatch]);

  const handleSubmit = () => {
    register({ email, firstname, lastname, company, password, conf_password: rePassword });
  };
  const onNextStep = e => {
    if (stepped && step < 3) {
      e.preventDefault();
      setStep(step + 1);
    }
  };
  const onPrevStep = () => {
    if (stepped && step > 1) {
      setStep(step - 1);
    }
  };
  const onError = e => {
    if (stepped && e.length) {
      setStep(e[0].props.step);
    }
  };

  ValidatorForm.addValidationRule('isPasswordMatch', value => value === password);

  return registerForm.success ? (
    <p className="register-success">
      Looking Good. <br />
      We have just sent you an email to complete your registration.
    </p>
  ) : (
    <div className="form">
      {!stepped && <p className="required-input">Required Field*</p>}
      <ValidatorForm onSubmit={handleSubmit} onError={onError}>
        <div className={step !== 1 && stepped ? 'v-hidden' : ''}>
          <TextValidator
            label="First name*"
            variant="outlined"
            validators={['required']}
            errorMessages={['First name is required']}
            onChange={e => setFirstname(e.target.value.trim())}
            value={firstname}
            step={1}
          />
          <TextValidator
            label="Last name*"
            variant="outlined"
            validators={['required']}
            errorMessages={['Last name is required']}
            onChange={e => setLastname(e.target.value.trim())}
            value={lastname}
            step={1}
          />
        </div>
        <div className={step !== 2 && stepped ? 'v-hidden' : ''}>
          <TextValidator
            label="Email*"
            variant="outlined"
            validators={['required', 'isEmail']}
            errorMessages={['Email is required', 'Email is not valid']}
            onChange={e => setEmail(e.target.value.trim())}
            value={email}
            step={2}
          />
          <TextValidator
            label="Company Name"
            variant="outlined"
            onChange={e => setCompany(e.target.value.trim())}
            value={company}
            step={2}
          />
        </div>
        <div className={step !== 3 && stepped ? 'v-hidden' : ''}>
          <TextValidator
            label="Create Password*"
            variant="outlined"
            type="password"
            validators={['required', 'minStringLength:12']}
            errorMessages={['Password is required', 'Password should be at least 12 characters']}
            onChange={e => setPassword(e.target.value.trim())}
            value={password}
            step={3}
          />
          <TextValidator
            label="Confirm Password*"
            variant="outlined"
            type="password"
            validators={['required', 'isPasswordMatch']}
            errorMessages={['Field is required', "Passwords don't match"]}
            onChange={e => setRePassword(e.target.value.trim())}
            value={rePassword}
            step={3}
          />
        </div>

        <div className="action-buttons">
          {step !== 1 && stepped && (
            <Button className="half-w" onClick={onPrevStep}>
              <KeyboardBackspaceOutlinedIcon /> PREVIOUS STEP
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            disabled={registerForm.loading}
            className={stepped && 'half-w'}
            onClick={onNextStep}
          >
            {registerForm.loading ? (
              <CircularProgress size={28} />
            ) : stepped && step !== 3 ? (
              'Next Step'
            ) : (
              'Register'
            )}
          </Button>
          {registerForm.error && (
            <p className="MuiFormHelperText-root Mui-error">{registerForm.error}</p>
          )}
        </div>
      </ValidatorForm>
    </div>
  );
}

export default RegisterForm;
